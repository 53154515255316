.WebMessaging {
  .ContactManagements {
    .contact-name {
      @include greaterThan($screen-sm) {
        margin-top: 1.9em;
      }
    }

    .contact-management-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-right: 20px;
      }
    }
  }

  .SendMessage {
    .AddPhoneNumber {
      .react-tagsinput {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
        overflow: hidden;
        padding-left: 5px;
        padding-top: 5px;
      }

      .invalid {
        border-color: #dc3545;
      }

      .react-tagsinput--focused {
        border-color: #80bdff;
      }

      .react-tagsinput-tag {
        background-color: $primary;
        border-radius: 0.25rem;
        border: 1px solid #d3d4d1;
        color: #fff;
        display: inline-block;
        align-items: center;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 5px;
      }

      .react-tagsinput-remove {
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;

        &::before {
          color: #fff;
          margin-left: 5px;
          content: ' ×';
        }
      }

      .react-tagsinput-input {
        background: transparent;
        border: 0;
        color: #777;
        outline: none;
        box-shadow: none;
        margin-bottom: 6px;
        margin-top: 1px;
        outline: none;
        padding: 5px;
        width: 161px;
      }
    }

    .shorten-url {
      color: #72738c;
      padding: 0;
      outline: none;
      box-shadow: none;

      i {
        font-size: unset;
      }
    }

    .mcmc-regulation {
      font-style: italic;
    }

    .badge-wrapper {
      text-transform: uppercase;

      .badge {
        font-size: unset;
        font-weight: unset;
      }

      .badge-light-success {
        background-color: #d2ffe8;
        color: #39da8a;
      }

      .badge-light-primary {
        background-color: #e2ecff;
        color: #f58025;
      }

      .badge-light-danger {
        background-color: #ffdede;
        color: #ff5b5c;
      }
    }

    .schedule-management-action {
      .bx-block.done {
        color: #828d99;
        cursor: default;
      }

      .bx-play-circle {
        color: #39da8a;
      }

      .bx-play-circle.done {
        color: #828d99;
        cursor: default;
      }
    }
  }
}

.AddContactModal {
  .custom-file {
    cursor: pointer;

    .custom-file-label {
      display: flex;
      align-items: center;
    }

    span {
      color: $tertiaryText;
      font-weight: 400;
      line-height: 1.4;
    }

    input {
      cursor: pointer;
      height: 100%;
    }

    .invalid {
      border-color: #dc3545;
    }
  }

  .file-format {
    label {
      color: $invalidText;
    }

    .form-text {
      font-size: 85%;
    }
  }
}

.SendLaterModal {
  .file-format {
    label {
      color: $invalidText;
    }
    .form-text {
      font-size: 85%;
    }
  }
}
