.modal-content {
  border-radius: 8px;
  border: 0;

  .modal-header {
    border-radius: 0;
    border-bottom: 1px solid $borderColor;

    .modal-title {
      width: 100%;
      font-weight: normal;
      font-size: 1.2rem;
      color: $tertiaryText;
    }

    button {
      span {
        color: white;
      }
    }
    position: relative;
    min-height: 3em;
    background: none;

    img {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 25px;
      cursor: pointer;
    }
  }

  .modal-body {
    label {
      color: $tertiaryText;
      font-size: $labelFontSize;
      font-weight: 500;
      text-transform: capitalize;
    }

    small {
      color: $invalidText;
    }

    .modal-loading {
      text-align: center;
      .lds-ellipsis div {
        background: $primary;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid $borderColor;
    border-radius: 0;
    padding-right: 26px;
  }
}
